'use client';

import React from 'react';
import { useLottie } from 'lottie-react';

import {
  home_automations,
  home_hosting,
  home_intelligence,
  home_sources,
  home_tracks,
} from '@/code-samples';
import CallToAction from '@/components/CallToAction';
import Code from '@/components/Code';
import Icon from '@/components/Icon';
import IconBox from '@/components/IconBox';
import { useRef, useEffect, useState } from 'react';
import Button from '@/components/Button';
import Testimonial from '@/components/Testimonial';

import FeaturesLottie from './FeaturesLottie2.json';
import { initFadeInObserver } from '@/utils';

// Feature component to reuse for each feature card
const Feature = ({ icon, iconColor = 'purple', title, description }) => (
  <div className="bg-brand-purple/10 rounded-lg px-4 py-6 flex flex-col items-center text-center">
    <Icon
      icon={icon}
      className={`w-10 h-10 md:w-12 md:h-12 text-brand-${iconColor} mb-4`}
    />
    <h3 className="text-xl/[1em] tracking-[-0.0125em] font-bold text-1 mb-2 xl:mb-4 font-inter">
      {title}
    </h3>
    <p className="text-3 text-base/[1.25em]">{description}</p>
  </div>
);

// Section component to reuse for each scrolling section
const Section = ({ id, color, icon, eyebrow, title, text, code }) => (
  <div
    className="min-h-[80vh] flex flex-col justify-center py-16"
    id={id ? `section-${id}` : null}>
    {icon && eyebrow ? (
      <div className="flex items-center gap-2 lg:gap-3 mb-3">
        <IconBox icon={icon} color={color} size="md" />
        <span
          className={`text-sm md:text-base xl:text-lg font-code uppercase ${color ? `text-brand-${color}` : 'text-brand-purple'}`}>
          {eyebrow}
        </span>
      </div>
    ) : null}
    <h3 className="text-2xl md:text-3xl/[1em] xl:text-5xl/[1em] font-bold text-1 mb-6 tracking-[-0.0125em] font-inter">
      {title}
    </h3>
    <p className="text-lg 2xl:text-xl/[1.25em] text-3 mb-8 font-inter font-regular">
      {text}
    </p>
    {code ? (
      <div className="grid">
        <Code
          language="js"
          className="max-h-[360px] max-w-screen overflow-hidden overflow-y-auto"
          wrap={false}>
          {code}
        </Code>
      </div>
    ) : null}
    <div className="flex flex-col items-start mt-6">
      <Button href="/docs" label={`View docs`} />
    </div>
  </div>
);

export default function LottieTest() {
  const scrollSectionRef = useRef(null);
  const lottieWrapperRef = useRef(null);
  const lottieContentRef = useRef(null);
  const lottieRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  // const [currentSection, setCurrentSection] = useState(1);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const style = {
    width: '100%',
    height: 'auto',
  };

  const options = {
    animationData: FeaturesLottie,
  };
  const lottieObj = useLottie(options, style);
  const LottieAnimation = lottieObj.View;
  lottieRef.current = lottieObj;

  // Define testimonials data
  const testimonials = [
    {
      text: 'Ittybit helps us serve video lessons reliably and affordably. That means 80,000 kids can train wherever they are in the world.',
      name: 'Simon Brundish',
      title: 'Founder, Strength:Lab',
      avatar: 'https://ittybit-app.ittybitcdn.com/img/simon.jpg',
      logo: 'https://ittybit-app.ittybitcdn.com/img/strengthlab-logo-white@2x.png',
      image:
        'https://ittybit-app.ittybitcdn.com/med_49im8p54YLdPzCC1/sl-image.png',
      color: 'purple',
    },
    {
      text: 'Ittybit enables Blackbook to support user-generated content without worrying about weird formats, malicious files, or inappropriate material',
      name: 'Becky Fatemi',
      title: 'Founder, BlackBook',
      avatar:
        'https://ittybit-app.ittybitcdn.com/med_49ilDo65xWfkvXld/becky.jpg',
      logo: 'https://ittybit-app.ittybitcdn.com/med_49ilDo66T9UJ8VKl/blackbook-logo.png',
      image:
        'https://ittybit-app.ittybitcdn.com/med_49ilxt34oRFrMyHB/blackbook-image.png',
      color: 'purple',
    },
  ];

  useEffect(() => {
    // Initialize fade-in effect
    initFadeInObserver();

    const scrollSection = scrollSectionRef.current;
    const lottieWrapper = lottieWrapperRef.current;
    const lottieContent = lottieContentRef.current;

    if (!scrollSection || !lottieWrapper || !lottieContent) return;

    // Get the position where we want to start the animation
    // This is the position of the scrollSection minus some offset to start earlier
    let startPosition = 0;

    const handleScroll = () => {
      // If we haven't calculated the start position yet, do it now
      if (startPosition === 0) {
        const scrollSectionRect = scrollSection.getBoundingClientRect();
        // Start the animation when the section is this many pixels from the top of the viewport
        const startOffset = window.innerHeight * 0.5; // Start when section is 50% of the way up the screen
        startPosition = window.scrollY + scrollSectionRect.top - startOffset;
        startPosition = Math.max(0, startPosition); // Ensure it's not negative
      }

      // Calculate the total scrollable distance for the animation
      const scrollSectionHeight = scrollSection.scrollHeight;
      const animationDistance = scrollSectionHeight * 0.8; // Use 80% of the section height for the animation

      // Calculate current scroll position relative to our start position
      const currentPosition = window.scrollY - startPosition;

      // Calculate progress (0 to 1)
      let progress = Math.min(
        1,
        Math.max(0, currentPosition / animationDistance),
      );

      setScrollProgress(progress);

      // Determine which section is currently in view
      // const sections = Array.from(
      //   scrollSection.querySelectorAll('[id^="section-"]'),
      // );
      // let activeSection = 1;

      // for (let i = 0; i < sections.length; i++) {
      //   const section = sections[i];
      //   const sectionRect = section.getBoundingClientRect();
      //   const windowHeight = window.innerHeight;

      //   if (
      //     sectionRect.top <= windowHeight / 2 &&
      //     sectionRect.bottom >= windowHeight / 2
      //   ) {
      //     activeSection = i + 1;
      //     break;
      //   }
      // }

      // setCurrentSection(activeSection);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // This function would be used to control the Lottie animation based on scroll progress
  useEffect(() => {
    // Control Lottie animation based on scrollProgress
    if (lottieRef.current) {
      const totalFrames = 1000;

      // Ensure we stay within valid frame range (0 to totalFrames-1)
      // Add a small buffer to prevent disappearing at extremes
      const minFrame = 0;
      const maxFrame = totalFrames - 1;

      // Map scrollProgress (0-1) to a slightly smaller range (0.01-0.99)
      // This keeps the animation visible at the extremes
      const adjustedProgress = 0.01 + scrollProgress * 0.98;

      const currentFrame = Math.min(
        maxFrame,
        Math.max(minFrame, Math.floor(adjustedProgress * totalFrames)),
      );

      lottieRef.current.goToAndStop(currentFrame, true);
    }
  }, [scrollProgress]);

  // Add useEffect for testimonial rotation
  useEffect(() => {
    const testimonialInterval = setInterval(() => {
      setCurrentTestimonialIndex(
        prevIndex => (prevIndex + 1) % testimonials.length,
      );
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clean up interval when component unmounts
    return () => clearInterval(testimonialInterval);
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      {/* Hero Section - Always visible */}
      <div className="w-full max-w-7xl mx-auto px-4 pt-16 pb-16 md:pt-24 md:pb-24 xl:pt-40 xl:pb-40">
        <div className="flex flex-col items-center">
          {/* Centered text */}
          <div className="w-full">
            <h1 className="text-center text-4xl md:text-6xl/[1em] xl:text-9xl/[1em] text-1 mb-6 xl:mb-12 tracking-[-0.025em] font-inter font-black">
              Build media features
              <br className="hidden md:block" /> in minutes
            </h1>

            {/* Custom code diff with syntax highlighting - centered */}
            <div className="flex flex-col items-center max-w-2xl mx-auto">
              <div className="flex flex-col items-stretch font-code text-base md:text-lg lg:text-xl 2xl:text-2xl w-full">
                <div className="flex flex-grow items-start bg-brand-magenta/20 px-3 py-1.5 rounded-t-md">
                  <span className="text-1 mr-3">-</span>
                  <span className="text-1 bg-brand-magenta/20 whitespace-nowrap">
                    <span className="text-brand-blue">const</span> pain
                  </span>
                  <span className="whitespace-nowrap">
                    <span className="text-brand-orange px-1">=</span>
                  </span>
                  <span className="bg-brand-magenta/20">
                    <span className="text-brand-yellow">"s3 permissions"</span>
                    <span className="text-brand-orange"> + </span>
                    <span className="text-brand-yellow">
                      "ffmpeg copypasta"
                    </span>
                  </span>
                </div>
                <div className="flex flex-grow items-start bg-brand-green/20 px-3 py-1.5 rounded-b-md">
                  <span className="text-1 mr-3">+</span>
                  <span className="text-1 bg-brand-green/20 whitespace-nowrap">
                    <span className="text-brand-blue">let</span> ittybit
                  </span>
                  <span className="whitespace-nowrap">
                    <span className="text-brand-orange px-1">=</span>
                  </span>
                  <span className="bg-brand-green/20">
                    <span className="text-brand-yellow">
                      "scalable media handling in mins"
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6 md:mt-12 xl:mt-16">
              <div className="flex flex-col lg:flex-row items-center justify-center gap-4 xl:gap-8">
                <Button
                  href="/register"
                  size="xl"
                  label="Get API Key"
                  primary
                />
                <Button href="/docs" size="xl" label="View Docs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dashboard Preview - Fade in with robot mascots on sides */}
      <div className="fade-in w-full max-w-[800px] xl:max-w-[1200px] 2xl:max-w-[1600px] mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0 relative">
        {/* <div className="fade-in w-full max-w-[800px] xl:max-w-[960px] 2xl:max-w-[1200px] mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0 relative"> */}
        {/* Left robot mascot - hidden on mobile */}
        {/* <picture className="hidden md:block absolute -left-24 lg:-left-32 xl:-left-48 -top-8 z-10">
          <source
            srcSet="https://ittybit-app.ittybitcdn.com/upload.webp"
            type="image/webp"
          />
          <img
            src="https://ittybit-app.ittybitcdn.com/upload.gif"
            alt="Robot mascot"
            className="w-48 lg:w-56 xl:w-64"
            width={440}
            height={800}
          />
        </picture> */}

        {/* Right robot mascot - hidden on mobile */}
        {/* <picture className="hidden md:block absolute -right-24 lg:-right-32 xl:-right-48 -bottom-8 z-10">
          <source
            srcSet="https://ittybit-app.ittybitcdn.com/base.webp"
            type="image/webp"
          />
          <img
            src="https://ittybit-app.ittybitcdn.com/base-left.gif"
            alt="Robot mascot"
            className="w-48 lg:w-56 xl:w-64"
            width={360}
            height={570}
          />
        </picture> */}

        {/* Video embed - narrower but still aspect ratio */}
        <div className="relative aspect-video">
          <video
            src="https://ittybit-app.ittybitcdn.com/ittybit-media.mp4"
            className="absolute inset-0 w-full h-full"
            autoPlay
            muted
            loop
          />
          {/* <iframe
            src="https://www.loom.com/embed/15f529c9d8c34768adba1aab1366f416?sid=6aa5b309-de06-4447-b17a-10a54e713744"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            className="absolute inset-0 w-full h-full"></iframe>
          */}
        </div>
      </div>

      {/* Object Storage Enhanced Section - Fade in */}
      <div className="fade-in w-full max-w-5xl mx-auto px-4 pt-16 lg:pt-24 2xl:pt-32 pb-8 lg:pb-12 2xl:pb-16">
        <h2 className="text-3xl md:text-5xl/[1em] xl:text-7xl/[1em] text-1 mb-4 xl:mb-6 tracking-[-0.0125em] font-inter font-black text-center">
          Everything your app needs for video, audio, and image files
        </h2>
        <p className="text-xl 2xl:text-2xl text-3 mb-8 text-center">
          We built ittybit for ourselves because we needed something faster and
          simpler than stitching together AWS services, but much more flexible
          (and affordable) than other hosted media services.
        </p>
      </div>

      {/* Features Grid - Fade in */}
      <div className="fade-in w-full max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pb-16 lg:pb-24 2xl:pb-32">
        <Feature
          icon="media"
          iconColor="purple"
          title="Storage made for media"
          description="Handle weird image formats, large videos, and audio input with a single consistent API"
        />
        <Feature
          icon="intelligence"
          iconColor="magenta"
          title="Intelligence built-in"
          description="No more opaque bytes! Analyse files and extract rich data using built-in AI features"
        />
        <Feature
          icon="transform"
          iconColor="green"
          title="Encode &amp; transform"
          description="Crop, compress, and convert random uploads to standard modern formats"
        />
        <Feature
          icon="cdn"
          iconColor="blue"
          title="Low-latency delivery"
          description="Built-in CDN gets media to users in milliseconds, all for a single global rate"
        />
        <Feature
          icon="automation"
          iconColor="yellow"
          title="Scale with ease"
          description="Broadcast-grade systems let you handle millions of files without infra headaches"
        />
        <Feature
          icon="stats"
          iconColor="pink"
          title="The data you need"
          description="Understand your media usage, and get insights into your users' behavior"
        />
        <Feature
          icon="security"
          iconColor="orange"
          title="Secure-by-default"
          description="We handle multiregion backups, and all data is encrypted at rest and in transit"
        />
        <Feature
          icon="terminal"
          iconColor="purple"
          title="By devs, for devs"
          description="We sweat the small details to create a delightful developer experience"
        />
      </div>

      <div className="fade-in w-full max-w-[1600px] mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0">
        <CallToAction primary />
      </div>

      <div className="fade-in w-full max-w-5xl mx-auto px-4 pt-16 pb-16">
        <h2 className="text-3xl md:text-5xl/[1em] xl:text-7xl/[1em] text-1 mb-8 tracking-[-0.0125em] font-inter font-black text-center">
          One API to store, transform, and get rich intelligence from your media
        </h2>
        {/* <p className="text-xl text-3 mb-8 font-inter text-center"></p> */}

        {/* Custom code diff with syntax highlighting */}
        <div className="flex flex-col items-center max-w-3xl mx-auto">
          <div className="flex flex-col md:flex-row items-stretch font-code text-base md:text-lg lg:text-xl w-full">
            {/* Left column - Removed code */}
            <div className="flex-1 flex flex-col">
              <div className="bg-brand-magenta/20 px-3 py-1.5 rounded-t-md rounded-b-none md:rounded-l-md md:rounded-r-none">
                <div className="text-1">
                  {/* <div>
                    <span className="text-1 mr-3">-</span>
                    <span className="text-brand-pink py-1">[</span>
                  </div> */}
                  <div>
                    <span className="text-1 mr-3">-</span>
                    <span className="text-brand-yellow bg-brand-magenta/20">
                      "5 services and glue code"
                    </span>
                  </div>
                  <div>
                    <span className="text-1 mr-3">-</span>
                    <span className="text-brand-yellow bg-brand-magenta/20">
                      "wtf is in this bucket?"
                    </span>
                  </div>
                  <div>
                    <span className="text-1 mr-3">-</span>
                    <span className="text-brand-yellow bg-brand-magenta/20">
                      "literally. too. many. bills"
                    </span>
                  </div>
                  {/* <div>
                    <span className="text-1 mr-3">-</span>
                    <span className="text-brand-pink">]</span>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Right column - Added code */}
            <div className="flex-1 flex flex-col">
              <div className="bg-brand-green/20 px-3 py-1.5 rounded-t-none rounded-b-md md:rounded-l-none md:rounded-r-md">
                <div className="text-1">
                  {/* <div>
                    <span className="text-1 mr-3">+</span>
                    <span className="text-brand-pink py-1">[</span>
                  </div> */}
                  <div>
                    <span className="text-1 mr-3">+</span>
                    <span className="text-brand-yellow bg-brand-green/20">
                      "1 consistent api"
                    </span>
                  </div>
                  <div>
                    <span className="text-1 mr-3">+</span>
                    <span className="text-brand-yellow bg-brand-green/20">
                      "built-in intelligence"
                    </span>
                  </div>
                  <div>
                    <span className="text-1 mr-3">+</span>
                    <span className="text-brand-yellow bg-brand-green/20">
                      "legit cheap"
                    </span>
                  </div>
                  {/* <div>
                    <span className="text-1 mr-3">+</span>
                    <span className="text-brand-pink py-1">]</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Scrolling Section with Lottie Animation - Fade in */}
      <div className="fade-in w-full max-w-7xl 2xl:max-w-[1600px] mx-auto px-4 mb-24">
        {/* New container structure - using grid instead of absolute positioning */}
        <div
          className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-12 gap-8"
          ref={scrollSectionRef}>
          {/* Sticky Lottie container - now in a grid cell instead of absolute positioning */}
          <div
            className="hidden md:block relative 2xl:col-span-7"
            ref={lottieWrapperRef}>
            <div
              ref={lottieContentRef}
              className="sticky top-24 h-[calc(100vh-12rem)]">
              <div className="h-full w-full flex flex-col items-center justify-center">
                {LottieAnimation}
                {/* <p className="text-xl font-inconsolata text-white">
                  Progress: {Math.round(scrollProgress * 100)}%<br />
                  Section: {currentSection}
                </p> */}
              </div>
            </div>
          </div>
          {/* Content sections */}
          <div className="w-full 2xl:col-span-5">
            <Section
              id={1}
              icon="upload"
              eyebrow="hosting"
              title="Easy uploads, secure storage, and rapid global delivery"
              text="Add fast, reliable, uploads to your product in minutes. Store all your app's video, image, and audio files securely. Download or stream your media globally with built-in CDN."
              code={home_hosting}
              color="blue"
            />

            <Section
              id={2}
              icon="intelligence"
              eyebrow="intelligence"
              title="Make your video, image, and audio files more useful"
              text="Describe or summarize images, videos, and audio. Make media searchable by extracting text, objects, and speech. Detect and filter out NSFW uploads before your users see them."
              code={home_intelligence}
              color="magenta"
            />

            <Section
              id={3}
              icon="sources"
              eyebrow="sources"
              title="Convert, compress, and transform your media files"
              text="Convert files to modern formats for maximum compatibility. Compress media files up to 90% without sacrificing quality. Resize and crop content to specific aspect ratios."
              code={home_sources}
              color="green"
            />

            <Section
              id={4}
              icon="tracks"
              eyebrow="tracks"
              title="Make your media player engaging and accessible"
              text="Add poster images to increase clickthrough and playback rates. Improve SEO and accessibility with automatic subtitles and captions. Help viewers navigate with interactive chapter markers."
              code={home_tracks}
              color="orange"
            />
          </div>
        </div>
      </div>

      <div className="fade-in w-full max-w-[1600px] mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0">
        <CallToAction primary />
      </div>

      <div className="fade-in w-full max-w-5xl mx-auto px-4 pt-16 pb-16">
        <h2 className="text-3xl md:text-5xl/[1em] xl:text-7xl/[1em] text-1 mb-8 tracking-[-0.0125em] font-inter font-black text-center">
          Build robust media workflows that scale-up with you
        </h2>
        {/* <p className="text-xl text-3 mb-8 font-inter text-center"></p> */}

        {/* Custom code diff with syntax highlighting */}
        <div className="flex flex-col items-start mx-auto">
          <div className="flex flex-col items-stretch font-inconsolata text-base md:text-lg lg:text-xl">
            <div className="flex flex-grow items-start bg-brand-magenta/20 px-3 py-1.5 rounded-t-md">
              <span className="text-1 py-1 mr-2">
                <span className="whitespace-nowrap">
                  <span className="text-1 mr-3">-</span>
                  <span className="text-brand-blue">const </span> requirements
                  <span className="text-brand-orange"> = </span>
                </span>
              </span>
              <span className="bg-brand-magenta/20">
                <span className="text-brand-yellow">"GPUs"</span>
                <span className="text-brand-orange">, </span>
                <span className="text-brand-yellow">"microservices"</span>
                <span className="text-brand-orange">, </span>
                <span className="text-brand-yellow">"exponential backoff"</span>
              </span>
            </div>
            <div className="flex flex-grow items-start bg-brand-green/20 px-3 py-1.5 rounded-b-md">
              <span className="text-1 py-1 mr-2">
                <span className="whitespace-nowrap">
                  <span className="text-1 mr-3">+</span>
                  <span className="text-brand-blue">const </span> requirements
                  <span className="text-brand-orange"> = </span>
                </span>
              </span>
              <span className="bg-brand-green/20">
                <span className="text-brand-yellow">
                  "about 30 lines of JSON"
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="fade-in w-full max-w-[1600px] mx-auto px-4 md:px-8 xl:px-12 2xl:px-0">
        <div className="relative w-full flex flex-col">
          <div className="w-full grid grid-cols-1 grid-rows-1 relative sm:px-12 lg:px-24 2xl:pl-0">
            <picture>
              <source
                srcSet="https://ittybit-app.ittybitcdn.com/auto-animation.webp"
                type="image/webp"
              />
              <img
                src="https://ittybit-app.ittybitcdn.com/auto-animation-fallback.png"
                alt="workflow example animation"
                width={1920}
                height={960}
                className="relative col-start-1 col-end-1 row-start-1 row-end-1 w-full aspect-video object-contain z-10"
              />
            </picture>
          </div>
          <picture>
            <source
              srcSet="https://ittybit-app.ittybitcdn.com/automations.webp"
              type="image/webp"
            />
            <img
              src="https://ittybit-app.ittybitcdn.com/automations.gif"
              alt="Robot mascot"
              className="absolute -bottom-8 left-0 md:left-8 w-32 md:w-48 lg:w-56 xl:w-[400px] z-10"
              width={620}
              height={610}
            />
          </picture>
        </div>
      </div>

      {/* Scrolling Section with Lottie Animation - Fade in */}
      <div className="fade-in w-full max-w-7xl 2xl:max-w-[1600px] mx-auto px-4 mb-24">
        {/* New container structure - using grid instead of absolute positioning */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 xl:gap-12">
          {/* Content sections */}
          <div className="hidden md:flex w-full flex-col items-center justify-center">
            <img
              src="https://ittybit-app.ittybitcdn.com/automation-runs.png"
              alt="automation runs"
              className="w-full"
              width={1280}
              height={720}
            />
          </div>

          <div className="relative">
            <div className="h-full w-full flex flex-col items-center justify-center">
              <Section
                color="yellow"
                icon="automation"
                eyebrow="Automations"
                title="Handle millions of files with one minute of setup"
                text="Automations let you trigger workflows for every new upload. Tasks can run in parallel, in sequence, or based on conditional logic. As your product grows ittybit's broadcast-grade infrastructure will scale up alongside you."
                code={home_automations}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fade-in w-full max-w-[1600px] mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0">
        <CallToAction primary />
      </div>
      <div className="fade-in w-full max-w-5xl mx-auto mb-24 px-4 md:px-8 xl:px-12 2xl:px-0">
        {/* Testimonial container with transition effects */}
        <div className="relative h-[400px] md:h-[350px] lg:h-[300px]">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`absolute w-full transition-opacity duration-500 ${
                index === currentTestimonialIndex
                  ? 'opacity-100'
                  : 'opacity-0 pointer-events-none'
              }`}>
              <Testimonial
                text={testimonial.text}
                name={testimonial.name}
                title={testimonial.title}
                avatar={testimonial.avatar}
                logo={testimonial.logo}
                image={testimonial.image}
                color={testimonial.color}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
