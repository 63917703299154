import Button from '@/components/Button';

const CallToAction = ({
  docs = true,
  pricing = false,
  color = 'purple',
  primary = false,
}) => {
  let textColor = `text-brand-${color}`;
  let buttonColor = `bg-brand-${color}/20 hover:bg-brand-${color} hover:bg-opacity-100 text-brand-${color} hover:text-white border border-brand-${color} focus:border-brand-purple`;
  if (primary) {
    buttonColor = `bg-brand-500 hover:bg-brand-purple hover:bg-opacity-100 text-white border border-brand-500 focus:border-brand-purple`;
  }
  return (
    <div className="w-full flex flex-col xl:flex-row xl:justify-between justify-between items-center">
      <div className="xl:m-4 text-center xl:text-left ">
        <h2 className="text-3xl md:text-4xl 2xl:text-5xl font-bold font-title text-1 ">
          Get started for free
        </h2>
        <h2
          className={`text-xl md:text-2xl 2xl:text-3xl font-code ${textColor}`}>
          10GB tasks. 100GB storage. 1TB delivery
        </h2>
      </div>
      <div className="mt-4 md:mt-6">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-4 2xl:gap-12">
          <Button
            href="/register"
            size="xl"
            label="Get API Key"
            className={buttonColor}
            custom
          />
          {docs ? <Button href="/docs" size="xl" label="View Docs" /> : null}
          {pricing ? (
            <Button href="/pricing" size="xl" label="View Pricing" />
          ) : null}
          {/* <Link
                href="/docs"
                className="text-base text-brand-500 font-medium px-5 py-3 bg-white hover:bg-brand-10 my-1.5"
              >
                View Quick Start
              </Link> */}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
