import CodeBlock from '@/components/CodeBlock';
import clsx from 'clsx';

const VALID_LANGUAGES = [
  'applescript',
  'astro',
  'bash',
  'blade',
  'c',
  'c#',
  'clj',
  'clojure',
  'cmd',
  'cobol',
  'coffee',
  'console',
  'cpp',
  'cs',
  'csharp',
  'css',
  'dart',
  'diff',
  'docker',
  'dockerfile',
  'elixir',
  'elm',
  'erb',
  'erl',
  'erlang',
  'git-commit',
  'git-rebase',
  'go',
  'graphql',
  'haskell',
  'hjson',
  'html',
  'http',
  'java',
  'javascript',
  'js',
  'json',
  'json5',
  'jsonc',
  'jsonl',
  'jsx',
  'kotlin',
  'latex',
  'less',
  'liquid',
  'lisp',
  'lua',
  'make',
  'makefile',
  'markdown',
  'matlab',
  'md',
  'mdx',
  'nginx',
  'nix',
  'objc',
  'objective-c',
  'objective-cpp',
  'pascal',
  'perl',
  'perl6',
  'php',
  'postcss',
  'powershell',
  'prisma',
  'proto',
  'pug',
  'py',
  'python',
  'r',
  'razor',
  'rb',
  'rs',
  'ruby',
  'rust',
  'sass',
  'scala',
  'scheme',
  'scss',
  'sh',
  'shell',
  'shellscript',
  'shellsession',
  'smalltalk',
  'solidity',
  'sql',
  'ssh-config',
  'svelte',
  'swift',
  'terminal',
  'text',
  'toml',
  'ts',
  'tsx',
  'twig',
  'txt',
  'typescript',
  'v',
  'vb',
  'vim',
  'viml',
  'vimscript',
  'vue',
  'vue-html',
  'wasm',
  'wgsl',
  'xml',
  'yaml',
  'yml',
  'zsh',
];

function Code({
  title = null,
  language = 'js',
  color = 'purple',
  icon = null,
  children,
  className,
  wrap = true,
}) {
  let code = '';
  // Handle both string and object cases for children
  const content =
    typeof children === 'object' ? children.props.children : children;

  // Split into lines and filter out empty lines
  const lines = content?.split('\n').filter(line => line.length > 0) || [];

  if (lines.length > 0) {
    const languageMatch = lines[0].trim().match(/^([a-zA-Z0-9]+)$/i);
    if (languageMatch) {
      const requestedLanguage = languageMatch[1].toLowerCase();
      if (VALID_LANGUAGES.has(requestedLanguage)) {
        language = requestedLanguage;
        code = lines.slice(1).join('\n');
      } else {
        // Fall back to default if language isn't in whitelist
        code = lines.join('\n');
      }
    } else {
      code = lines.join('\n');
    }
  }

  if (!color) {
    color = '';
  }
  if (!icon) {
    icon = '';
  }

  return (
    <div
      title={title}
      className={clsx(
        'code-block w-full rounded-2xl overflow-hidden shadow-[0_4px_32px_0px] shadow-brand-purple/10',
        className,
      )}>
      <CodeBlock
        language={language}
        code={code}
        className="text-xs md:text-sm leading-5 md:leading-6 xl:leading-6 w-full overflow-auto"
        lineNumbers={true}
        wrap={wrap}
      />
    </div>
  );
}

export default Code;
