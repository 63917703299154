//// naming conventions
// slug_component_language

//// HOME PAGE
export const home_hosting = `
await fetch('https://your-project.ittybit.net/uploaded-video.mov', {
  method: 'PUT',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: file
});
`;

export const home_intelligence = `
const task = {
  url: 'https://your-project.ittybit.net/uploaded-video.mov',
  kind: 'summary',
}
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: JSON.stringify(task)
});
`;

export const home_sources = `
const task = {
  url: 'https://your-project.ittybit.net/uploaded-video.mov',
  width: 1280,
  height: 720,
  fit: 'cover',
  layers: [
    { kind: 'image', url: '/logo.png', right: 0 }
  ] 
`;

export const home_tracks = `
<video>
  <source src='https://your-project.ittybit.net/uploaded-video.mp4' />
  <track src='https://your-project.ittybit.net/uploaded-video/subtitles.vtt' kind='subtitles' />
  <track src='https://your-project.ittybit.net/uploaded-video/chapters.vtt' kind='chapters' />
  <track src='https://your-project.ittybit.net/uploaded-video/thumbnails.vtt' kind='metadata' />
</video>
`;

export const home_automations = `const automation = {
  trigger: { event: 'media.created' },
  workflow: [
    { kind: 'nsfw', confidence: 0.6,
      next: [{
        kind: 'conditions',
        conditions: [{ prop: 'detected', value: true }],
        next: [{ kind: 'webhook', url: 'https://your.api/ittybit' }]
      },{ 
        kind: 'conditions',
        conditions: [{ prop: 'detected', value: false }],
        next: [{
          kind: 'video', width: 720, height: 1280, label: 'web',
          next: [{
            kind: 'image', format: 'png', label: 'poster',
            next: [
              { kind: 'webhook', url: 'https://your.api/ittybit' },
              { kind: 'speech', speakers: true,
                next: [{ kind: 'subtitles', format: 'vtt' }]
              },
              { kind: 'chapters', format: 'vtt' }
            ]}
          ]}
        },{ 
          kind: 'image', long_edge: 240, label: 'thumbnail' 
        }]
      }]
    },
    { kind: 'description' }
  ]
}

const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify(automation)
});`;

//// APIS
// apis/hosting

export const apis_hosting_uploadFiles = `
await fetch('https://your-project.ittybit.net/uploaded-file.mp4', {
  method: 'PUT',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: file
});
`;

/// good boilerplate for tasks
export const apis_hosting_ingestFromUrls = `
const task = {
  url: 'https://your-project.ittybit.net/movie.mp4',
  kind: 'ingest',
}
await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: JSON.stringify(task)
});
`;

export const apis_hosting_storeSecurely = `
await fetch('https://api.ittybit.com/files/file_abcdefgh1234', {
  method: 'PATCH',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify({
    access: [
      {
        kind: 'signature',
        valid: true,
        action: 'allow'
      },
      {
        kind: 'default',
        action: 'deny'
      },
    ]
  })
});
`;

export const apis_hosting_deliverGlobally = `
<!-- video -->
<video src='https://your-project.ittybit.net/your-video.mp4'></video>
<!-- audio -->
<audio src='https://your-project.ittybit.net/your-audio.mp3'></audio>
<!-- image -->
<img src='https://your-project.ittybit.net/your-image.jpeg' />
`;

// apis/intelligence

export const apis_intelligence_description = `
const task = {
  url: 'https://your-project.ittybit.net/image-to-analyze.jpg',
  kind: 'description',
}`;

export const apis_intelligence_description_response = `
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify(task)
});
{
  'kind': 'description',
  'text': 'A vibrant nighttime street scene in a bustling entertainment district of Japan. The street is awash in neon lights and colorful signage, creating a dazzling, cyberpunk-like atmosphere. Large illuminated signs advertise various businesses, including Bariki, a Thai yoga massage parlor, and Hyakkendana.'
}
`;

export const apis_intelligence_speech = `
const task = {
  url: 'https://your-project.ittybit.net/video-to-analyze.mp4',
  kind: 'speech',
  speakers: true,
}`;

export const apis_intelligence_speech_response = `
{
  'kind': 'speech',
  'detected': true,
  'timeline': [
    {
      'start': 0,
      'end': 10,
      'text': 'Hello, and welcome to UkeTube. I'm John Doe.',
      'speaker': 0,
      'confidence': 0.98
    },
    {
      'start': 10,
      'end': 20,
      'text': 'And I'm Jessie Doe. Today we're going to learn how to play Viva La Vida by Coldplay.',
      'speaker': 1,
      'confidence': 0.95
    }
  ]
`;

export const apis_intelligence_labels = `
const task = {
  url: 'https://your-project.ittybit.net/video-to-analyze.mp4',
  kind: 'objects',
}`;

export const apis_intelligence_labels_response = `
{
  'kind': 'objects',
  'detected': true,
  'items': [
    {
      'label': 'Nike soccer cleats',
      'confidence': 0.85,
      'box': {'x': 300, 'y': 450, 'w': 200, 'h': 100}
    },
    {
      'label': 'Uhlsport soccer ball',
      'confidence': 0.95,
      'box': {'x': 450, 'y': 300, 'w': 100, 'h': 100}
    },
    {
      'label': 'White soccer shorts',
      'confidence': 0.97,
      'box': {'x': 100, 'y': 100, 'w': 300, 'h': 200}
    },
    {
      'label': 'Red advertising board',
      'confidence': 0.97,
      'box': {'x': 0, 'y': 0, 'w': 800, 'h': 100}
    },
    {
      'label': 'White soccer socks',
      'confidence': 0.98,
      'box': {'x': 200, 'y': 300, 'w': 150, 'h': 250}
    },
    {
      'label': 'Soccer field (grass)',
      'confidence': 0.99,
      'box': {'x': 0, 'y': 400, 'w': 800, 'h': 200}
    },
  ]
}
`;

export const apis_intelligence_summarize = `
const task = {
  url: 'https://your-project.ittybit.net/audio-to-analyze.mp3',
  kind: 'summary',
}`;

export const apis_intelligence_summarize_response = `
{
  'kind': 'summary',
  'title': 'Designing at Scale: Airbnb's Journey with Design Systems',
  'tags': ['Design systems', 'Airbnb', 'Product development', 'Scalability', 'UI/UX', 'Accessibility', 'React Sketchapp', 'Organizational change'],
  'text': 'Karri Saarinen, formerly of Airbnb, presents a compelling case for design systems as a solution to the increasing complexity of product development in the digital age. He argues that design systems are not just about aesthetics, but are crucial tools for organizational efficiency, product consistency, and scalability.Saarinen delves into Airbnb's approach to design systems, emphasizing the importance of functional components and primitives. He demonstrates how their system allows designers and engineers to work more efficiently across multiple platforms, resulting in a more cohesive user experience.'
}
`;

export const apis_intelligence_nsfw = `
const task = {
  url: 'https://your-project.ittybit.net/image-to-analyze.jpg',
  kind: 'nsfw',
}`;

export const apis_intelligence_nsfw_response = `
{
  'kind': 'nsfw',
  'detected': true,
  'items': [
    {
      'category': 'nudity',
      'confidence': 0.82
    }
  ]
}`;

// apis/sources 5

export const apis_sources_convert = `
const task = {
  url: 'https://your-project.ittybit.net/iphone-video.hevc',
  format: 'mp4',
}
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_API_KEY'},
  body: JSON.stringify(task)
});`;

export const apis_sources_compress = `
const task = {
  url: 'https://your-project.ittybit.net/giant-dashcam-video.mp4',
  filename: 'compressed-dashcam-video.mp4',
  quality: 75,
}`;

export const apis_sources_resize = `
const task = {
  url: 'https://your-project.ittybit.net/huge-profile-pic.heic',
  filename: 'resized-avatar.webp',
  format: 'webp',
  width: 800,
  height: 800,
  fit: 'cover'
}`;

export const apis_sources_trim = `
const task = {
  url: 'https://your-project.ittybit.net/members-only/lesson.mp4',
  filename: 'trailer.mp4',
  start: 0,
  end: 60,
}
`;

export const apis_sources_watermark = `
const task = {
  url: 'https://your-project.ittybit.net/uploaded-video.mp4',
  filename: 'branded-video.mp4',
  width: 720,
  start: 0,
  end: 120,
  layers: [
    {
      url: url,
      height: 200,
      bottom: 400,
    },
    {
      text: '@username',
      fontSize: 16
      bottom: 360,
    }
  ]
}
`;

// apis/tracks 4
export const apis_tracks_poster1 = `
const task = {
  url: 'https://your.domain/festivals/video.mp4',
  kind: 'image',
  filename: 'poster.jpg'
}
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: JSON.stringify(task)
});`;

export const apis_tracks_poster2 = `
<video 
  poster='https://your.domain/festivals/poster.jpg'
>
  <source src='https://your.domain/festivals/video.mp4' />
</video>`;

export const apis_tracks_subtitles1 = `
const task = {
  url: 'https://your.domain/festivals/video.mp4',
  kind: 'subtitles',
}`;

export const apis_tracks_subtitles2 = `
<video 
  poster='https://your.domain/festivals/poster.jpg'
  width='1920' height='1080'
>
  <source src='https://your.domain/festivals/video.mp4' />
  <track src='https://your-project.ittybit.net/festivals/subtitles.vtt' kind='subtitles' />  
</video>
`;

export const apis_tracks_chapters1 = `
const task = {
  url: 'https://your.domain/festivals/video.mp4',
  kind: 'chapters',
}
`;

export const apis_tracks_chapters2 = `
<video 
  poster='https://your.domain/festivals/poster.jpg'
  width='1920' height='1080'
>
  <source src='https://your.domain/festivals/video.mp4' />
  <track src='https://your-project.ittybit.net/festivals/subtitles.vtt' kind='subtitles' />  
  <track src='https://your.domain/festivals/chapters.vtt' kind='chapters' />
</video>`;

export const apis_tracks_thumbnails1 = `
const task = {
  url: 'https://your.domain/festivals/video.mp4',
  kind: 'thumbnails',
}
`;

export const apis_tracks_thumbnails2 = `
<video 
  poster='https://your.domain/festivals/poster.jpg'
  width='1920' height='1080'
>
  <source src='https://your.domain/festivals/video.mp4' />
  <track src='https://your-project.ittybit.net/festivals/subtitles.vtt' kind='subtitles' />
  <track src='https://your.domain/festivals/chapters.vtt' kind='chapters' />
  <track src='https://your.domain/festivals/thumbnails.vtt' kind='metadata' />
</video>
`;

// apis/automations 4

export const apis_automations_workflow = `
const automation = {
  workflow: [
    {
      kind: 'description',
    },
    {
      kind: 'speech',
      speakers: true,
    },
    {
      kind: 'video',
      format: 'mp4',
      layers: [
        {
          url: 'https://your.domain/logo.png',
          width: 100,
          bottom: 25,
          right: 25,
        }
      ],
      next: [
        {
          format: 'jpeg',
          label: 'poster'
          
        }
      ]
    },
  ]
}
const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: JSON.stringify(automation)
});
`;

export const apis_automations_triggers = `
const automation = {
  trigger: {
    event: 'media.created',
  },
};
`;

export const apis_automations_notified = `
const workflow = [
  {
    kind: 'nsfw',
    threshold: 0.5,
    next: [{  
      kind: 'conditions',  
      conditions: [  
        { prop: 'detected', value: true }  
      ],  
      next: [{  
        kind: 'webhook',  
        url: 'https://your.api.com/manual-review',  
      }]  
    }]  
  },
]
`;

export const apis_automations_scale = `
[
  {  
  id: 'task_abcdefgh12345678',  
  kind: 'workflow',  
  input: { 
  },  
  workflow: [   
  ],  
  created: '2025-02-25T05:25:56.000Z',  
  updated: '2025-02-25T05:26:16.000Z',  
  status: 'complete'  
  }  
]
`;

//// DOCS
// docs landing: code doesn't run without template literals

// docs/hosting
//

export const docs_hosting_simpleupload_js = `
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const URL = 'YOUR FILE URL HERE'
const response = await fetch('https://api.ittybit.com/files', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(url: URL), 
});
`;

export const docs_hosting_simpleupload_bash = `
curl -X POST 'https://api.ittybit.com/files'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY'
-H 'Content-Type: application/json'
-d '{
  "url": "YOUR_URL_HERE",
  }'
`;

export const docs_hosting_resumableupload_js = `
const response = await fetch('https://api.ittybit.com/uploads', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: JSON.stringify({
      resumable: true,
      filename: 'usr_abcd/profile/video',
      metadata: {
        filename: 'original-filename.mp4',
        user_id: 'usr_abcd',
      },
    }),
});
`;

export const docs_hosting_resumableupload_bash = `
`;

export const docs_hosting_simpleresponse_json = `
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/uploads',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'url': 'https://yourdomain.com/store/single?key=aWQ9QU...aWQ9QU==',
  },
  'links': {}
`;

// {
//   "meta": {
//       "id": "req_527nTu61zGQDraCcFLIavnnj",
//       "method": "POST",
//       "url": "/files",
//       "version": "2024-03-21",
//       "status": 200,
//       "org_id": "org_01J9QT9Q853B3P8PV33G8FR0YZ",
//       "project_id": "proj_48ffSi44Vy53",
//       "object": "source",
//       "created": "2025-03-08T23:55:44.448Z"
//   },
//   "data": {
//       "id": "file_527nTu98e84gBtBa",
//       "object": "source",
//       "kind": "video",
//       "format": "mp4",
//       "type": "video/mp4",
//       "width": 1920,
//       "height": 1080,
//       "duration": 300,
//       "fps": 30,
//       "filesize": 69867830,
//       "folder": "med_50nf1f67vW9THHl6",
//       "filename": "file_50nf1f67312PuneF",
//       "url": "https://ju-testing.ittybit.net/med_50nf1f67vW9THHl6/file_50nf1f67312PuneF",
//       "created_by": "task_527nTu99kN5VE4iw",
//       "created": "2025-03-08T23:55:30.988Z",
//       "updated": "2025-03-08T23:55:43.375Z"
//   },
//   "links": {
//       "self": "https://api.ittybit.com/files"
//   }
// }

export const docs_hosting_resumableresponse_json = `
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/uploads',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'url': 'https://yourdomain.com/store/resumable?key=aWQ9QU...aWQ9QU==',
    'conditions': [
        { 'prop': 'kind', 'operator': '==', 'value': 'video' },
        { 'prop': 'filesize', 'operator': '<=', 'value': 104857600 },
      ],
    'folder': 'usr_abcd',
    'filename': 'profile.mp4',
    'metadata': {
      'filename': 'original-filename.mp4',
      'user_id': 'usr_abcd',
    }
  },
  'links': {}
`;

export const docs_hosting_uploadthefile_simpleupload_js = `
const { data } = await response.json();
const file = document.getElementById('file').files[0];
const result = await fetch(data.url, {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
  body: new FormData({
      file: file,
  }),
});
`;

export const docs_hosting_uploadthefile_resumableupload_js = `
const { data } = await response.json();
const url = data.url;
const CHUNK_SIZE = 1024 * 1024 * 10; 
const file = document.getElementById('file').files[0];
const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
async function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });
}
async function uploadFile({ file, url }) {
  let parts = [];
  let arrayBuffer = await readFileAsArrayBuffer(file);
  for (let i = 0; i < totalChunks; i++) {
    let start = i * CHUNK_SIZE;
    let end = Math.min(file.size, start + CHUNK_SIZE);
    let chunk = arrayBuffer.slice(start, end); 
    let response = await fetch(url, {
      method: 'PUT', 
      headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' }, 
      body: chunk
    });
    if (!response.ok) {
        throw new Error('Upload failed');
    }
    let { data } = await response.json();
    parts.push(data); // #F
  }
  let response = await fetch(url, {
    method: 'POST',  
    headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
    body: JSON.stringify({ parts })
  });
  if (!response.ok) {
      throw new Error('Upload completion failed');
  }
  console.log('Upload complete');
  let result = await response.json();
  return result;
}
const result = await uploadFile({ file, url });
`;

export const docs_hosting_optionalsavemediadetails_result_json = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'type': 'object',
    'status': 200,
  },
  'data': {
    'id': 'file_abcdefgh1234',
    'object': 'media',
    'kind': 'video',
    'width': 1920,
    'height': 1080,
    'duration': 60,
    'filesize': 12345678,
    'alias': 'usr_abcd/profile/video',
    'url': 'https://yourdomain.com/usr_abcd/profile/video/',
    'sources': [
      {
        'id': 'src_abcdefgh1234',
        'object': 'source',
        'label': 'original',
        'url': 'https://yourdomain.com/file_abcdefgh1234/original',
      }
    ],
    'intelligence': [],
    'metadata': {
      'filename': 'original-filename.mp4',
      'user_id': 'usr_abcd',
    },
    'created': '2024-03-30T13:00:00Z',
    'updated': '2024-03-30T13:00:00Z',
    'status': 'active'
  },
  'links': {
    'self': 'https://api.ittybit.com/media/file_abcdefgh1234',
    'parent': 'https://api.ittybit.com/media',
  }
}
`;

export const docs_hosting_uploadconditions_js = `
const FILESIZE_LIMIT = 100*1024*1024; 
const MAX_LENGTH = 1920;
const MAX_DURATION = 5 * 60; 
const conditions = [
  { prop: 'kind', operator: '==', value: 'video' },
  { prop: 'filesize', operator: '<=', value: FILESIZE_LIMIT },
  { prop: 'width', operator: '<=', value: MAX_LENGTH },
  { prop: 'height', operator: '<=', value: MAX_LENGTH },
  { prop: 'duration', operator: '<=', value: MAX_DURATION }
]
`;

// docs/intelligence
//
export const docs_intelligence_extractSpeech = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'speech',
}

const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task)  
});
`;

export const docs_intelligence_sampleResponse = `
'meta':{
  'id': 'req_abcdefgh1234',
  'org_id': 'org_abcdefgh1234',
  'project_id': 'prj_abcdefgh5678',
  'method': 'POST',
  'host': 'https://api.ittybit.com',
  'path': '/tasks',
  'version': '2024-08-21',
  'status': 200,
  'type': 'object'
},
'data': {
  'id': 'task_abcdefgh5678',
  'object': 'task',
  'input': 'file_abcdefgh1234',
  'output': {
    'kind': 'speech',
    'filename': 'speech.vtt',
  },
  'status': 'processing',
  'created': '2024-03-30T15:00:00Z',
  'updated': '2024-03-30T15:00:00Z',
},
'links': {
  'self': 'https://api.ittybit.com/tasks/task_abcdefgh5678',
  'parent': 'https://api.ittybit.com /tasks',
}
`;

export const docs_intelligence_bodyParameters = `
  {
   'file_id': 'file_abcdefgh1234', 
    'kind': 'speech',
    'confidence': 0.5 
    }
  }
`;

// docs/sources
//
export const docs_sources_endpoints = `
GET https://api.ittybit.com/sources
POST https://api.ittybit.com/sources
`;

export const docs_sources_sampleRequest = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'video', 
  format: 'mp4' 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task)  
});
`;

export const docs_sources_sampleResponses = `
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh5678',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/tasks',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'task_abcdefgh5678',
    'object': 'task',
    'file_id': 'file_abcdefgh1234', 
    'kind': 'video',
    'format': 'mp4',
    },
    'status': 'processing',
    'created': '2024-03-30T15:00:00Z',
    'updated': '2024-03-30T15:00:00Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/tasks/task_abcdefgh5678',
    'parent': 'https://api.ittybit.com /tasks',
  }
`;

export const docs_sources_bodyParameters = `
    'file_id': 'file_abcdefgh1234',
    'kind': 'video',
    'format': 'mp4'
  }
}`;

// docs/tracks
// 4
export const docs_tracks_endpoints = `
GET https://api.ittybit.com/tasks/:task_id
POST https://api.ittybit.com/tasks
`;

export const docs_tracks_sampleRequest = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'subtitles', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task)  
});
`;

export const docs_tracks_sampleResponse = `
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh5678',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/tasks',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'task_abcdefgh5678',
    'object': 'task',
    'input': 'file_abcdefgh1234',
    'output': {
      'kind': 'subtitles',
    },
    'status': 'processing',
    'created': '2024-03-30T15:00:00Z',
    'updated': '2024-03-30T15:00:00Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/tasks/task_abcdefgh5678',
    'parent': 'https://api.ittybit.com /tasks',
  }
`;

export const docs_tracks_bodyParameters = `
{
  'file_id': 'file_abcdefgh1234', 
  'kind': 'subtitles',
}
`;

// docs/automations
// 2
export const docs_automations_endpoints = `
POST https://api.ittybit.com/automations 
GET https://api.ittybit.com/automations
`;

export const docs_automations_extractSpeech = `
const response = await fetch('https://api.ittybit.com/automations', {
method: 'POST',  
headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },   
body: JSON.stringify({
	'name': 'All video uploads',
	'trigger': {
		'event': 'media.created'
	},
	'tasks': [
		{
			'kind': 'image',
			'start': 0
		}, {
			'kind': 'video',
			'width': 600,
			'format': 'mp4',
			'filename': '600.mp4',
			'next': [
				{
					'kind': 'speech',
					'next': [
						{
							'kind': 'subtitles'
						}
					]
				},
				{
					'kind': 'thumbnails'
				}
			]
		}
	]
	}),
});
`;

// docs/api
// 3

export const docs_api_baseUrl = `
const BASE_URL = 'https://api.ittybit.com'
`;

export const docs_api_request_js = `
const response = await fetch('https://api.ittybit.com/uploads', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify({
    filename: 'user-avatar.jpg',
    metadata: {
      user_id: '42',
      user_name: 'Douglas Adams',
    },
  }),
});
`;

export const docs_api_request_sh = `
curl -X POST 'https://api.ittybit.com/uploads'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
-H 'Content-Type: application/json'
-d '{
  'filename': 'user-avatar.jpg',
  'metadata': {
    'user_id': '142',
    'user_name': 'Douglas Adams'
  }
}'
`;

// docs/tasks
// 6

export const docs_tasks_endpoints = `
# Transform Tasks
GET https://api.ittybit.com/tasks
POST https://api.ittybit.com/tasks
# Task Details
GET https://api.ittybit.com/tasks/:task_id
`;

export const docs_tasks_createTask_js = `
const task = {
  url: 'https://demo-files.ittybit.net/video.mp4', 
  format: 'jpeg'
}
  
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task)
});`;

export const docs_tasks_createTask_bash = `curl -X POST 'https://api.ittybit.com/tasks'
-H 'Authorization: Bearer ITTYBIT_API_KEY'
-H 'Content-Type: application/json'
-d '{
  "url": "https://demo-files.ittybit.net/video.mp4", 
  "format": "jpeg"
}'`;

export const docs_tasks_sampleResponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh5678',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/tasks',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'task_abcdefgh5678',
    'object': 'task',
    'input': 'file_abcdefgh1234',
    'output': {
      'kind': 'image',
      'filename': 'poster.jpg',
      'width': 640,
    },
    'status': 'processing',
    'created': '2024-03-30T15:00:00Z',
    'updated': '2024-03-30T15:00:00Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/tasks/task_abcdefgh5678',
    'parent': 'https://api.ittybit.com /tasks',
  }
}
`;

export const docs_tasks_view_js = `
const BASE_URL = 'https://api.ittybit.com';
const response = await fetch('https://api.ittybit.com/tasks/task_abcdefgh1234', {
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
});
`;

export const docs_tasks_view_bash = `
curl 'https://api.ittybit.com/tasks/task_abcdefgh1234'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
`;

export const docs_tasks_response = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'GET',
    'host': 'https://api.ittybit.com',
    'path': '/tasks/task_abcdefgh1234',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'task_abcdefgh1234',
    'object': 'task',
    'input': 'file_abcdefgh1234',
    'output': {
      'id': 'file_abcdefgh1234',
      'object': 'source',
      'kind': 'image',
    },
    'status': 'ready',
    'created': '2024-03-30T13:00:00Z',
    'updated': '2024-03-30T13:00:01Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/tasks/task_abcdefgh1234',
    'parent': 'https://api.ittybit.com/tasks',
  }
}
`;

export const docs_tasks_list_js = `
const BASE_URL = 'https://api.ittybit.com';
const response = await fetch('https://api.ittybit.com/tasks', {
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY'}
});
`;

export const docs_tasks_list_bash = `
curl 'https://api.ittybit.com/tasks'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
`;

export const docs_tasks_list_response = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'GET',
    'host': 'https://api.ittybit.com',
    'path': '/tasks',
    'version': '2024-08-21',
    'status': 200,
    'type': 'list',
    'total': 18,
    'limit': 12,
    'page': 1,
    'pages': 2
  },
  'data': [
    {
    'id': 'task_abcdefgh1234',
    'object': 'task',
    'input': 'file_abcdefgh1234',
    'output': {
      'kind': 'image',
      'filename': 'poster.jpg',
      'width': 640,
    },
    'created': '2024-03-30T13:00:00Z',
    'updated': '2024-03-30T13:00:01Z',
    'status': 'ready'
    }
  ],
  'links': {
    'self': 'https://api.ittybit.com/tasks',
    'next': 'https://api.ittybit.com/tasks?page=2',
    'prev': null,
  }
}
`;

// docs/events

export const docs_events = `
'trigger': {
    'event': 'media.created',
}
`;

// docs/media

export const docs_media_endpoints = `
# Media
POST https://api.ittybit.com/media
GET https://api.ittybit.com/media
# Media Item
GET https://api.ittybit.com/media/:id
PATCH https://api.ittybit.com/media/:id
DELETE https://api.ittybit.com/media/:id
`;

export const docs_media_createmedia_js = `
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const URL = 'YOUR FILE URL HERE'
const response = await fetch('https://api.ittybit.com/media', {
    method: 'POST',  
    headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
    body: JSON.stringify({
      url: URL,
      filename: 'new-filename.mp4',
      folder: 'your/folder/path',
      title: 'My Video Upload',
      metadata: {
        original_filename: 'your-file.mp4',
        user_id: '123',
        source: 'YourApp/iOS',
        purpose: 'profile_video',
      },
    }),
});
`;

export const docs_media_createmedia_sh = `
curl -X POST 'https://api.ittybit.com/media'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
-H 'Content-Type: application/json'
-d '{
  "url": "https://yourbucket.s3.amazonaws.com/your-file.mp4?signature=1234567890&expires=1234567890",
  "filename": "new-filename.mp4",
  "folder": "your/folder/path",
  "title": "My Video Upload",
  "metadata": {
    "original_filename": "your-file.mp4",
    "user_id": "123",
    "source": "YourApp/iOS",
    "purpose": "profile_video"
  }
}'
`;

export const docs_media_postresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/media',
    'version': '2024-08-21',
    'status': 200,
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh5678',
    'type': 'object'
  },
  'data': {
    'id': 'file_abcdefgh5678',
    'object': 'media',
    'title': 'My Video Upload',
    'folder': 'your/folder/path',
    'kind': 'video',
    'width': 1920,
    'height': 1080,
    'duration': 60.0,
    'fps': 30.0,
    'filesize': 12345678,
    'total_filesize': 12345678,
    'sources': [
    ],
    'tracks': [],
    'intelligence': [],
    'original': 'https://prj_abcdefgh1234.ittybit.net/your/folder/path/new-filename.mp4',
    'placeholder': 'data:image/png;base64,aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1kUXc0dzlXZ1hjUSZwcD15Z1VJY21samEzSnZiR3clM0Q=',
    'background': '#FF94A4',
    'metadata': {
      'original_filename': 'your-file.mp4',
      'user_id': '123',
      'source': 'YourApp/iOS',
      'purpose': 'profile_video'
    },
    'status': 'active',
    'created': '2024-08-30T15:00:00Z',
    'updated': '2024-08-30T15:00:01Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/media/file_abcdefgh5678',
    'parent': 'https://api.ittybit.com/media',
  }
}
`;

// {
//   "meta": {
//       "id": "req_5280lq09CYTVDNsIeuF1P0OV",
//       "method": "POST",
//       "url": "/files",
//       "version": "2024-03-21",
//       "status": 200,
//       "org_id": "org_01J9QT9Q853B3P8PV33G8FR0YZ",
//       "project_id": "proj_48ffSi44Vy53",
//       "object": "source",
//       "created": "2025-03-09T00:21:39.922Z"
//   },
//   "data": {
//       "id": "file_5280lq45avP8dFsl",
//       "object": "source",
//       "kind": "video",
//       "format": "mp4",
//       "type": "video/mp4",
//       "width": 1920,
//       "height": 1080,
//       "duration": 300,
//       "fps": 30,
//       "filesize": 69867830,
//       "folder": "med_50nf1f67vW9THHl6",
//       "filename": "file_50nf1f67312PuneF",
//       "url": "https://ju-testing.ittybit.net/med_50nf1f67vW9THHl6/file_50nf1f67312PuneF",
//       "created_by": "task_5280lq462L8bVXHO",
//       "created": "2025-03-09T00:21:26.451Z",
//       "updated": "2025-03-09T00:21:39.265Z"
//   },
//   "links": {
//       "self": "https://api.ittybit.com/files"
//   }
// }

export const docs_media_list_js = `
const response = await fetch('https://api.ittybit.com/media', {
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },
});
`;

export const docs_media_list_sh = `
curl 'https://api.ittybit.com/media'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
`;

export const docs_media_listresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'GET',
    'host': 'https://api.ittybit.com',
    'path': '/media',
    'query': {
      'your_custom_key': 'Your custom value',
    },
    'version': '2024-08-21',
    'status': 200,
    'type': 'list',
    'total': 120,
    'limit': 12,
    'page': 1,
    'pages': 10
  },
  'data': [
    {
    'id': 'file_abcdefgh1234',
    'object': 'media',
    'kind': 'video',
    'width': 1920,
    'height': 1080,
    'duration': 60,
    'fps': 30,
    'filesize': 12345678,
    'sources': [
    ],
    'tracks': [
    ],
    'intelligence': [
    ],
    'metadata': {
      'your_custom_key': 'Your custom value',
      'fav_number': '69',
      'is_nice': 'true'
    },
    'created': '2024-08-30T13:00:00Z',
    'updated': '2024-08-30T13:01:00Z',
    }
  ],
  'links': {
    'self': 'https://api.ittybit.com/media',
    'next': 'https://api.ittybit.com/media?page=2',
    'prev': null,
  }
}
`;

export const docs_media_view_js = `
const response = await fetch('https://api.ittybit.com/media/med_abcdefgh1234', {
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY'},
});
`;

export const docs_media_view_sh = `
curl 'https://api.ittybit.com/media/med_abcdefgh1234'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
`;

export const docs_media_viewresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'GET',
    'host': 'https://api.ittybit.com',
    'path': '/media/med_abcdefgh1234',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'med_abcdefgh1234',
    'object': 'media',
    'title': 'My Video Upload',
    'folder': 'your/folder/path',
    'kind': 'video',
    'width': 1920,
    'height': 1080,
    'duration': 60,
    'fps': 30,
    'filesize': 12345678,
    'total_filesize': 24681357,
    'sources': [
    ],
    'tracks': [
    ],
    'intelligence': [
    ],
    'original': 'https://prj_abcdefgh1234.ittybit.net/your/folder/path/new-filename.mp4',
    'poster': 'https://prj_abcdefgh1234.ittybit.net/your/folder/path/poster.jpg',
    'speech': 'https://prj_abcdefgh1234.ittybit.net/your/folder/path/speech.json',
    'subtitles': 'https://prj_abcdefgh1234.ittybit.net/your/folder/path/subtitles.srt',
    'placeholder': 'data:image/png;base64,aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1kUXc0dzlXZ1hjUSZwcD15Z1VJY21samEzSnZiR3clM0Q=',
    'background': '#FF94A4',
    'metadata': {
      'original_filename': 'your-file.mp4',
      'user_id': '123',
      'source': 'YourApp/iOS',
      'purpose': 'profile_video'
    },
    'created': '2024-08-30T13:00:00Z',
    'updated': '2024-08-30T13:01:00Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/media/med_abcdefgh1234',
    'parent': 'https://api.ittybit.com/media',
  }
}
`;

export const docs_media_update_js = `
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/media/med_abcdefgh1234', {
    method: 'PATCH',  
    headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
    body: JSON.stringify({
      title: 'My New Title',
      folder: 'new/folder/path',
      metadata: {
        'new_value': 'New value',
        'purpose': null
      }
    }),
  },
});
`;

export const docs_media_update_sh = `
curl -X PATCH 'https://api.ittybit.com/media/med_abcdefgh1234'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
-H 'Content-Type: application/json'
-d '{
  "title": "My New Title",
  "folder": "new/folder/path",
  "metadata": {
    "new_value": "New value",
    "purpose": null
  }
}'
`;

export const docs_media_updateresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh5678',
    'method': 'PATCH',
    'host': 'https://api.ittybit.com',
    'path': '/media',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'id': 'med_abcdefgh1234',
    'object': 'media',
    'title': 'My New Title',
    'folder': 'new/folder/path',
    'kind': 'video',
    'width': 1920,
    'height': 1080,
    'duration': 60,
    'fps': 30,
    'filesize': 12345678,
    'total_filesize': 24681357,
    'sources': [
    ],
    'tracks': [
    ],
    'intelligence': [
    ],
    'metadata': {
      'original_filename': 'your-file.mp4',
      'user_id': 123,
      'source': 'YourApp/iOS',
      'new_value': 'New value'
    },
    'status': 'active',
    'created': '2024-08-30T13:00:00Z',
    'updated': '2024-08-30T14:00:00Z',
  },
  'links': {
    'self': 'https://api.ittybit.com/media/med_abcdefgh1234',
    'parent': 'https://api.ittybit.com/media',
  }
}
`;

export const docs_media_delete_js = `
const response = await fetch('https://api.ittybit.com/media/med_abcdefgh1234', {
  method: 'DELETE',
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(thing) 
});
`;

export const docs_media_delete_sh = `
curl -X DELETE 'https://api.ittybit.com/media/med_abcdefgh1234'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
`;

export const docs_media_deleteresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'DELETE',
    'host': 'https://api.ittybit.com',
    'path': '/media/med_abcdefgh1234',
    'version': '2024-08-21',
    'status': 200,
    'type': null
  },
  'data': null,
  'links': {
    'parent': 'https://api.ittybit.com/media',
  }
}
`;

// docs/tasks

//// guides

// guides/hosting

export const guides_hosting_files_js = `
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const URL = 'YOUR FILE URL HERE'
const response = await fetch('https://api.ittybit.com/files', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify({url:}) 
});
`;

export const guides_hosting_files_sh = `
curl -X POST 'https://api.ittybit.com/files'
-H 'Authorization': 'Bearer ITTYBIT_API_KEY',
-H 'Content-Type: application/json'
-d '{
  "url": "YOUR_URL_HERE",
}'
`;

export const guides_hosting_simpleupload_js = `
const BASE_URL = 'https://api.ittybit.com';
const response = await fetch('https://api.ittybit.com/uploads', {
  method: POST,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' }
});
`;

export const guides_hosting_resumableupload_js = `
const BASE_URL = 'https://api.ittybit.com';
const FILESIZE_LIMIT = 100*1024*1024; 
const response = await fetch('https://api.ittybit.com/uploads', {
   method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify({
      resumable: true,
      filename: 'usr_abcd/profile/video',
      metadata: {
        filename: 'original-filename.mp4',
        user_id: 'usr_abcd',
      },
    }),
});
`;

export const guides_hosting_simpleresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/uploads',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'url': 'https://yourdomain.com/store/single?key=aWQ9QU...aWQ9QU==',
  },
  'links': {}
}
`;

export const guides_hosting_resumableresponse = `
{
  'meta':{
    'id': 'req_abcdefgh1234',
    'org_id': 'org_abcdefgh1234',
    'project_id': 'prj_abcdefgh1234',
    'method': 'POST',
    'host': 'https://api.ittybit.com',
    'path': '/uploads',
    'version': '2024-08-21',
    'status': 200,
    'type': 'object'
  },
  'data': {
    'url': 'https://yourdomain.com/store/resumable?key=aWQ9QU...aWQ9QU==',
    'folder': 'usr_abcd',
    'filename': 'profile.mp4',
    'metadata': {
      'filename': 'original-filename.mp4',
      'user_id': 'usr_abcd',
    }
  },
  'links': {}
}
`;
// guides/intelligence 6

export const guides_intelligence_extractspeech = `
const task = {
  file_id: 'file_abcdefgh1234',
  kind: 'speech', 
  confidence: 0.5 
}

const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_intelligence_detectobjects = `
const task = {
 file_id: 'file_abcdefgh1234',
 kind: 'objects',
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task)
});
`;

export const guides_intelligence_detectfaces = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'objects', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_intelligence_labelstext = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'objects', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_intelligence_detectnsfw = `
const task ={
  file_id: 'file_abcdefgh1234', 
  kind: 'nsfw', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_intelligence_bodyparameters = `
{
  'file_id': 'file_abcdefgh1234', 
  'kind': 'speech' 
}
`;

export const guides_intelligence_automations = `
const response = await fetch('https://api.ittybit.com/automations', {
    method: ‘POST’,  
    headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
    body: JSON.stringify({
	'name': 'All video uploads',
	'trigger': {
		'event': 'media.created',
	},
	'tasks': [
		{
			'kind': 'image',
			'start': 0
		}, 
  {
			'kind': 'video',
			'width': 600,
			'format': 'mp4',
			'filename': '600.mp4',
			'next': [
				{
					'kind': 'speech', 
					'next': [
						{
							'kind': 'subtitles' 
						}
					]
				},
				{
					'kind': 'thumbnails' 
				}
			]
		}
	]
	}),
});
`;
// guides/sources 6
export const guides_sources_subtitles = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'subtitles', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_sources_convert_mp4_to_jpg = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'image', 
  format: 'jpg' 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_sources_convert_mov_to_mp4 = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'video', 
  format: 'mp4' 
}

const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_sources_convert_video_to_audio = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'audio',
  format: 'mp3' 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_sources_compress_video = `
const task = {
  file_id: 'file_abcdefgh1234',
  kind: 'video', 
  format: 'mp4',
  height: 720, 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_sources_bodyparameters = `
{
  'file_id': 'file_abcdefgh1234', 
  'kind': 'video',
  'format': 'mp4' 
  }
}
`;

export const guides_sources_automations = `
const response = await fetch('https://api.ittybit.com/automations', {
    method: ‘POST’,  
    headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
    body: JSON.stringify({
      'name': 'All video uploads',
      'trigger': {
        'event': 'media.created'
      },
      'tasks': [
        {
          'kind': 'image',
          'start': 0
        }, {
          'kind': 'video',
          'width': 600,
          'format': 'mp4',
          'filename': '600.mp4',
          'next': [
            {
              'kind': 'speech',
              'next': [
                {
                  'kind': 'subtitles'
                }
              ]
            },
            {
              'kind': 'thumbnails'
            }
          ]
        }
      ]
	})
});
`;

// guides/tracks

export const guides_tracks_subtitles = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'subtitles',
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_tracks_chapters = `
const task = {
  file_id: 'file_abcdefgh1234',
  kind: 'chapters', 
}
const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_tracks_thumbnail_track = `
const task = {
  file_id: 'file_abcdefgh1234', 
  kind: 'thumbnail',
}

const ITTYBIT_API_KEY = 'YOUR API KEY HERE'
const response = await fetch('https://api.ittybit.com/tasks', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify(task) 
});
`;

export const guides_tracks_bodyparameters = `
{
  'file_id': 'file_abcdefgh1234', 
  'kind': 'subtitles' 
  }
}
`;

export const guides_tracks_automations = `
const response = await fetch('https://api.ittybit.com/automations', {
  method: ‘POST’,  
  headers: { 'Authorization': 'Bearer ITTYBIT_API_KEY' },  
  body: JSON.stringify({
    'name': 'All video uploads',
    'trigger': {
      'event': 'media.created'
    },
    'tasks': [
      {
        'kind': 'image',
        'start': 0
      }, {
        'kind': 'video',
        'width': 600,
        'format': 'mp4',
        'filename': '600.mp4',
        'next': [
          {
            'kind': 'speech',
            'next': [
              {
                'kind': 'subtitles'
              }
            ]
          },
          {
            'kind': 'thumbnails'
          }
        ]
      }
    ]
	}),
});
`;

// guides/automations3

export const guides_automations_trigger = `
trigger: {
      kind: 'event',
      event: 'media.created'
    }
`;

export const guides_automations_automations_after_upload = `
const trigger: {
      kind: 'event',
      event: 'media.created'
    }
const automation = {
  workflow: [
    {
      kind: 'description',
    },
    {
      kind: 'speech',
      speakers: true,
    },
    {
      format: 'mp4',
      max: '720px',
      layers: [
        {
          url: 'https://your.domain/logo.png',
          width: 100,
          bottom: 25,
          right: 25,
        }
      ],
      next: [
        {
          label: 'poster',
          format: 'jpeg',
        }
      ]
    },
  ]
}
const response = await fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {'Authorization': 'Bearer ITTYBIT_KEY'},
  body: JSON.stringify(automation)
});
`;

export const guides_automations_bodyparameters = `
const automation = {
    trigger: {
      kind: 'event',
      event: 'media.created'
    },
    workflow: [
    ]
}
`;
