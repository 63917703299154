import Link from "next/link";
import Icon from "@/components/Icon";

export default function Button({
  icon,
  label,
  sublabel,
  type = "button",
  href = null,
  size = "md",
  block,
  primary,
  secondary,
  custom,
  green,
  muted,
  destructive,
  disabled,
  className = "",
  onClick,
  nopad,
  reverse = false,
  newWindow = false,
  title,
  form,
}) {
  if (!label && !icon) return null;

  let isInternal = false;
  if (
    href?.[0] === "/" ||
    href?.includes("localhost") ||
    href?.includes("ittybit.local") ||
    href?.includes("ittybit.com")
  ) {
    isInternal = true;
  }
  if (newWindow) isInternal = false;

  let sizes = "";
  let height = "h-10";
  if (size === "xs") {
    sizes = "text-2xs lg:text-xs leading-4 lg:leading-6";
    height = "h-4 lg:h-6";
  }
  if (size === "sm") {
    sizes = "text-xs lg:text-sm leading-6 lg:leading-8";
    height = "h-6 lg:h-8";
  }
  if (size === "md") {
    sizes = "text-sm lg:text-base leading-6 lg:leading-8";
    height = "h-8 lg:h-10";
  }
  if (size === "lg") {
    sizes = "text-base lg:text-lg leading-8 lg:leading-12";
    height = "h-8 lg:h-12";
  }
  if (size === "xl") {
    sizes = "text-lg lg:text-xl leading-12 lg:leading-16";
    height = "h-12 lg:h-16";
  }

  let width = "";
  if (size === "xs") width = "w-8";
  if (size === "sm") width = "w-8";
  if (size === "md") width = "w-10";
  if (size === "lg") width = "w-12";
  if (size === "xl") width = "w-16 lg:w-24";
  if (label) width = "";
  if (block) width = "w-full";

  let padding = "";
  if (label) {
    padding = "px-4";
    if (size === "xs") padding = "px-2";
    if (size === "lg") padding = "px-8";
    if (size === "xl") padding = "px-12 lg:px-16";
  }
  if (icon && !label) {
    sizes = "h-10 w-10";
  }
  if (nopad) padding = "px-0";

  let hasLabel = label ? true : false;

  if (label && sublabel) {
    height = "";
    padding = `${padding} py-1 lg:py-2`;
    if (size === "xl") {
      padding = `${padding} py-2 lg:py-4`;
    }
    label = (
      <>
        <span className="whitespace-nowrap font-bold">{label}</span>
        <span className="block text-xs leading-3 opacity-70">{sublabel}</span>
      </>
    );
  } else if (label) {
    label = <span className="whitespace-nowrap font-bold">{label}</span>;
  }

  let colors = `bg-white bg-opacity-50 hover:bg-violet-50 hover:bg-opacity-100 
    dark:bg-opacity-5 dark:hover:bg-opacity-10
    text-2 hover:text-1`;
  if (primary) colors = "bg-brand-500 hover:bg-brand-300 text-white";
  if (green)
    colors =
      "bg-brand-green/5 hover:bg-brand-green/10 text-brand-green/90 hover:text-brand-green";
  if (green && primary)
    colors = "bg-brand-green/80 hover:bg-brand-green/90 text-brand-white";
  if (secondary)
    colors = `bg-violet-100 hover:bg-violet-200
      dark:bg-dark-300 dark:hover:bg-dark-500 
      text-violet-800 hover:text-violet-600
      dark:text-dark-text-2 dark:hover:text-dark-text-1
      `;
  if (muted)
    colors =
      "bg-transparent hover:bg-gray-50 text-gray-400 hover:text-gray-400";
  if (destructive)
    colors =
      "bg-brand-magenta/5 hover:bg-brand-magenta/10 text-brand-magenta/90 hover:text-brand-magenta";
  if (destructive && primary)
    colors = "bg-brand-magenta/90 hover:bg-brand-magenta text-white";

  let borders = "border border-transparent focus:border-brand-300";
  if (custom) {
    colors = "";
    borders = "";
  }

  let disable = "";
  if (disabled)
    disable = "disabled:cursor-not-allowed bg-opacity-50 text-opacity-50";

  let flexOrder = reverse ? "flex-row-reverse" : "flex-row";
  let classNames = `flex items-center justify-center text-center font-medium ring-0 focus:ring-0 outline-none rounded-md ${flexOrder} ${height} ${sizes} ${width} ${padding} ${colors} ${borders} ${disable} ${className}`;

  let iconSizes = "";

  let labelMargin = "";
  if (icon) {
    labelMargin = reverse ? "mr-2" : "ml-2";
  }

  if (hasLabel) {
    iconSizes = "w-6 h-6";
    if (size === "xs") iconSizes = `w-4 h-4`;
    if (size === "sm") iconSizes = `w-4 h-4`;
    if (size === "lg") iconSizes = `w-6 h-6`;
    if (size === "xl") iconSizes = `w-8 h-8`;
  } else {
    iconSizes = "w-8 h-8";
    if (size === "xs") iconSizes = "w-4 h-4";
    if (size === "sm") iconSizes = "w-6 h-6";
    if (size === "lg") iconSizes = "w-10 h-10";
    if (size === "xl") iconSizes = "w-12 h-12";
  }

  if (isInternal) {
    return (
      <Link href={href} passHref className={classNames} title={title}>
        {icon ? (
          <div className={hasLabel ? `opacity-50` : ""}>
            <Icon icon={icon} className={iconSizes} />
          </div>
        ) : null}
        {hasLabel ? (
          <span
            className={`flex-col items-center justify-center ${labelMargin}`}
          >
            {label}
          </span>
        ) : null}
      </Link>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        target="_blank"
        rel="noopener"
        title={title}
      >
        {icon ? (
          <div className={hasLabel ? `opacity-50` : ""}>
            <Icon icon={icon} className={iconSizes} />
          </div>
        ) : null}
        {hasLabel ? (
          <span
            className={`flex-col items-center justify-center ${labelMargin}`}
          >
            {label}
          </span>
        ) : null}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        type={type}
        form={form}
        className={classNames}
        onClick={onClick}
        disabled={disabled}
        title={title}
      >
        {icon ? (
          <div className={hasLabel ? `opacity-50` : ""}>
            <Icon icon={icon} className={iconSizes} />
          </div>
        ) : null}
        {hasLabel ? (
          <span
            className={`flex-col items-center justify-center ${labelMargin}`}
          >
            {label}
          </span>
        ) : null}
      </button>
    );
  }

  return (
    <button
      type={type}
      className={classNames}
      disabled={disabled}
      title={title}
      form={form}
    >
      {icon ? (
        <div className={hasLabel ? `opacity-50` : ""}>
          <Icon icon={icon} className={iconSizes} />
        </div>
      ) : null}
      {hasLabel ? (
        <span className={`flex-col items-center justify-center ${labelMargin}`}>
          {label}
        </span>
      ) : null}
    </button>
  );
}
