import Icon from '@/components/Icon';

const Testimonial = ({
  text,
  image,
  name,
  title,
  avatar,
  logo,
  color = 'purple',
}) => {
  const textColor = `text-brand-${color}`;
  return (
    <div className="flex flex-col lg:flex-row items-center justify-start gap-4 bg-black rounded-lg shadow-[0_4px_32px_0px] shadow-brand-purple/10">
      <div className="flex flex-col items-stretch justify-stretch w-full gap-8 2xl:gap-12 p-8 2xl:p-16">
        <p className="text-xl md:text-2xl font-regular text-violet-100">
          <Icon
            icon="quote"
            className={`w-6 h-6 2xl:w-8 2xl:h-8 ${textColor} inline-block relative -top-1 md:-top-2 mr-2`}
          />
          {text}
          <Icon
            icon="quote"
            className={`w-6 h-6 2xl:w-8 2xl:h-8 ${textColor} inline-block relative -top-1 md:-top-2 ml-2 rotate-180`}
          />
        </p>
        <div className="flex flex-col md:flex-row items-start 2xl:items-end justify-between gap-4">
          <div className="flex items-center gap-4">
            <img
              src={avatar}
              width="48"
              height="48"
              alt={name}
              className="w-8 h-8 md:w-12 md:h-12 rounded-full object-cover object-center"
            />
            <div>
              <p className="text-sm md:text-base xl:text-lg font-medium text-1">
                {name}
              </p>
              <p className="text-sm md:text-base xl:text-lg text-3">{title}</p>
            </div>
          </div>
          <img
            src={logo}
            width="144"
            height="48"
            alt="Company Logo"
            className="hidden md:block"
          />
        </div>
      </div>
      <img
        src={image}
        width="488"
        height="400"
        alt=""
        className="hidden lg:block w-2/5 flex-shrink-0"
      />
    </div>
  );
};

export default Testimonial;
